import React, {useEffect, useState} from 'react';
import Layout from "../layout/layout";
import Breadcrumbs from "../profile/breadcrumbs";
import Seo from "../layout/seo";
import Banner from "../industries/banner";
import Solutionled from "./solutionled";
import Demandfeatures from "./demandfeatures";
import Revolutionindustries from "./revolutionindustries";
import Leadingdemand from "./leadingdemand";
import Globalmarket from "./globalmarket";
import Featured from "../home/featured";
import Partner from "../home/partner";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import {checkData} from "../../helper";
import Developmentprocess from "./developmentprocess";
import Loader from "../components/Loader";


function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/industries/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);


    return (
        <Layout>

            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="Technology" link2="Industries" link3={userData.name}/>
                    <Banner data={userData}/>
                    <Featured/>
                    <Solutionled data={userData}/>
                    <Demandfeatures data={userData}/>
                    <Revolutionindustries data={userData}/>
                    <Leadingdemand data={userData}/>
                    <Globalmarket data={userData}/>
                    <Developmentprocess data={userData}/>
                    <Partner/>
                </>
            }
        </Layout>
    );
}

export default Index;